<template>
  <div id="app">
    <el-container style="min-height: 100vh;">
      <!-- 顶部导航 -->
      <el-header class="header">
        <div class="header-left">
          <img src="./assets/logo.png" alt="Logo" class="logo">
          <span class="tagline">互联社区——社会治理智慧大脑</span>
        </div>
        <div class="header-right">
          <el-button type="text" @click="handleLogin">[登录/注册]</el-button>
        </div>
      </el-header>

      <!-- 大横幅 -->
      <div class="banner">
        <div class="banner-content">
        </div>
      </div>
      <!-- 卡片标题 -->
      <el-row  class="cardTitle">
        <el-col :span="24" >
            <div class="cardTitle1">平台功能应用场景</div>         
        </el-col>
   
      </el-row>
      <!-- 卡片区域 -->
      <el-main class="main-content">
     <div class="card-container">
      <el-row :gutter="20">
        <el-col :span="8" :xs="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(card, index) in cards" :key="index">
          <el-card class="fixed-card" shadow="hover">
            <img :src="card.image"  class="card-image">
            <div class="card-content">
              <h3>{{ card.title }}</h3>
              <p>{{ card.description }}</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </el-main>
  <!-- 产品价格标题 -->
  <el-row  class="cardTitle">
        <el-col :span="24" >
            <div class="cardTitle1">推荐产品</div>           
        </el-col>
      </el-row>
  <!-- 产品价格 -->
  <el-main class="price-content">
    <div class="price-container">
      <el-row :gutter="60">
        <el-col :span="8" :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-for="(price, index) in plan" :key="index">
          <el-card class="price-card">
            <div style="display: flex;flex-direction: column;justify-content: space-between;height: 410px;">
              <div>
            <div class="price-header">
              <h3>{{ price.title }}</h3>
              <p>{{ price.describe }}</p>
              <i>{{ price.price }}</i>
            </div>
            <div class="price-body">
              <ul class="feature-list">
                <li v-for="(feature, idx) in price.features" :key="idx">
                  <i class="el-icon-check"></i>
                  {{ feature }}
                </li>
              </ul>
            </div>
          </div>
            <div class="price-footer">
              <el-button @click="handleLogin" class="zhuCe" type="primary">立即注册</el-button>
            </div>
          </div>
          </el-card>
        
        </el-col>
      </el-row>
    </div>
  </el-main>
  <!-- 关于我们 -->
  <el-main class="about-us-container">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16">
        <div class="left-content">
          <h2>关于我们</h2>
          <p>致力于为社会治理创新提供全面信息技术解决方案的专业机构。团队十五年来，以新型数据、智慧、智能管理模式驱动基层治理创新，帮助基层政府组织实现人员高效管理、精准服务、信息传播以及协商共治、公益志愿管理等方面的一站式管理。</p>
          <p>先后为北京、山东、浙江、四川、海南、内蒙古等十余省市超过6000+基层组织实现数智化治理应用服务。</p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <img src="./assets/shequ.jpg" class="right-image">
      </el-col>
    </el-row>
  </el-main>
  <!-- 开启智慧平台 -->
  <!-- <el-main class="main-container">
      <el-row class="content-wrapper">
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <div class="card-header">
              <h2 class="card-title">开启智慧平台</h2>
              <p>免费体验</p>
            </div>
            <div class="card-footer">
              <el-button type="primary" @click="UpdateAnnouncement">使用手册</el-button>
              <el-button type="primary" plain @click="UpdateAnnouncement">更新公告</el-button>
            </div>
        </el-col>
      </el-row>
  </el-main> -->
<!-- 底部 -->
<div class="info-container" :style="{ backgroundColor: '#1b1b1b', color: '#aaa' }">
    <el-row  class="info-row" type="flex" justify="center" align="middle" :gutter="16">
      <el-col :span="8" :xs="24" :sm="8" class="info-col">
        <div class="info-content">
          <h3>地址</h3>
          <p>北京市东城区快易名商办公楼B023</p>
        </div>
      </el-col>
      <el-col :span="8" :xs="24" :sm="8" class="info-col">
        <div class="info-content">
          <h3>联系方式</h3>
          <p>1078737959@QQ.COM</p>
        </div>
      </el-col>
      <el-col :span="8" :xs="24" :sm="8" class="info-col">
        <div class="info-content">
          <img src="./assets/kefu.png" alt="QR Code" class="qrcode-image">
        </div>
      </el-col>
    </el-row>
    <hr class="divider" />
    <div class="footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="beian-link">北京互联云天科技有限公司 2012-2024 京ICP备2024066649号-1</a>
    </div>
  </div>

    </el-container>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      cards: [
        {
          image: require('./assets/chanpin1.jpg'),
          title: '社区管理',
          description: '辖区管理丨微网格丨人口管理丨爬楼图丨社工管理丨巡查管理丨请假管理丨档案管理'
        },        {
          image: require('./assets/diaocha.png'),
          title: '调查表单',
          description: '自定义表单丨跳转设置丨电子签名丨结果分析丨二维码丨用户关联'
        },        {
          image: require('./assets/hdgl.png'),
          title: '活动管理',
          description: '闯关丨打卡丨评比丨单场多场丨收费丨分人群限制报名丨电子签到丨自动积分'
        },        {
          image: require('./assets/chanpin4.jpg'),
          title: '服务预约',
          description: '办事指南丨线上资料预审丨服务预约丨空间预约丨多维自定义设置'
        },        {
          image: require('./assets/chanpin7.jpg'),
          title: '诉求工单',
          description: '巡查诉求丨居民诉求丨12345丨诉求画像丨工单流转丨满意度评价丨数据分析'
        },        {
          image: require('./assets/chanpin6.jpg'),
          title: '议事协商',
          description: '议题征集丨议题确认丨民意调查丨线上会议丨议事实施丨议题监督'
        },        {
          image: require('./assets/gyfb.png'),
          title: '公益反哺',
          description: '商城管理丨多类型积分丨规则设置丨订单管理丨自提点设置丨证书'
        },        {
          image: require('./assets/chanpin3.jpg'),
          title: '志愿管理',
          description: '志愿者丨志愿组织丨志愿活动丨在线签署协议丨自动积分丨精准帮扶'
        },        {
          image: require('./assets/shdy.png'),
          title: '社会动员',
          description: '志愿团队丨成员加入丨活动发布丨资讯风采丨大事记丨微心愿丨跳蚤市集'
        },        {
          image: require('./assets/chanpin5.jpg'),
          title: '服务商圈',
          description: '15分钟商户入驻丨优惠券丨优惠活动丨商户服务丨产品'
        },        {
          image: require('./assets/cbhd.jpg'),
          title: '传播互动',
          description: '小程序丨H5页面丨用户管理丨精准推送丨模板消息丨秀米丨短信包'
        },        {
          image: require('./assets/chanpin2.jpg'),
          title: '数据分析',
          description: '分类面板丨数据穿透丨用户画像丨社区画像丨诉求画像'
        },
      ],plan:[
        {
          title:'基础套餐',
          describe:'推荐小微社会组织丨社区（村）',
          price:'15000元/年',
          button:'立即注册',
          features: [
            'H5', '活动', '表单', '公众号', '社工管理', '志愿者','积分'
          ]
        },{
          title:'标准套餐',
          describe:'推荐社区（村）',
          price:'30000元/年',
          button:'立即注册',
          features: [
            '小程序/H5', '活动', '表单', '公众号', '社工管理', '志愿者管理', '爬楼图', '议事协商', '公益反哺', '诉求管理', '主动关怀'
          ]
        },{
          title:'全模块',
          describe:'推荐基层政府丨社区（村）',
          price:'50000元/年',
          button:'立即注册',
          features: [
          '小程序/H5', '活动', '表单', '公众号', '社工管理', '志愿者管理', '爬楼图', '议事协商', '公益反哺', '社会动员', '数据分析', '专属客服'
          ]
        }
      ]
      
    };
  },
  methods: {
    handleLogin() {
      window.location.href = 'http://www.sheshetong.com/admin/login';
    },
    UpdateAnnouncement() {
      window.open('https://www.shequdangjian.com.cn/admin.html#/viewUpdate?article_id=72')
    }
  }
};
</script>

<style scoped>

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #167eec;
  color: #fff;

  height: 60px;
  line-height: 60px;
}

.header-left {
  display: flex;
  align-items: center;
  max-width: calc(100% - 150px); /* 确保左侧不会太宽 */
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.tagline {
  font-size: 14px;
  white-space: nowrap; /* 防止文字换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 使用省略号表示被隐藏的文字 */
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right .el-button {
  color: #fff;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .tagline {
    font-size: 12px; 
  }

  .header-left {
    max-width: calc(100% - 100px); 
  }
}
.banner {
  background: url('./assets/banner.png') no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 20% 0;
  position: relative;

}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.cardTitle{
  margin: 50px auto 0px;
  .cardTitle1{
    display: flex;
    justify-content: center; 
    font-size: 36px;
    color: #3c3c3c;
    font-weight: 800;
    margin-bottom: 20px;
  }
}
/* 卡片区域 */
.main-content {
  padding: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.fixed-card {
  width: 100%;
  height: 290px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  margin: 10px 0;
}

.card-image {
  width: 100%;
  height: 170px; /* 固定图片高度 */
  object-fit: cover; /* 保持图片比例 */
}

.card-content {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: #3c3c3c;
}

.card-content p {
  margin: 0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: rgb(0, 112, 192);
}
/* 推荐产品 */

.price-content {
  padding: 20px;
}

.price-container {
  max-width: 1200px;
  margin: 0 auto;
}

.price-card {
  background-image: url(./assets/bg2.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  height: 450px;
}

.price-header, .price-footer {
  text-align: center;
  width: 100%;
}
.price-header h3{
  font-size: 24px;
  color: #3c3c3c;
  line-height: 18px;
}
.price-header p{
  color: #474747;
}
.price-header i{
  color: #218ad0;
  font-style: normal;
  margin-bottom: 15px;
  display: block;
}
.el-icon-check{
  font-weight: 800;
  color: #1b83e1;
  margin-right: 10px;
}

.feature-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 0; 
    margin: 0; 
}

.feature-list li {
    width: 48%; 
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box; 
    margin-left: 5px;
}

.icon {
  margin-right: 10px;
}


/* 关于我们 */
.about-us-container {
  padding: 20px;
  background-color: #f1f7ff;
}
.left-content {
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
.left-content h2{
  font-weight: 400;
  font-size: 32px;
}
.left-content p{
  font-weight: 400;
      font-size: 16px;
      color: rgb(102, 102, 102);
      line-height: 30px;
      margin-right: 20px;
}
.right-image {
  width: 100%;
  max-height: 300px; /* 可以根据实际需求调整图片显示高度等样式 */
  object-fit: cover;
}
/* 开启智慧平台 */
.main-container{
  background-image: url(./assets/bg1.png);
}
.content-wrapper {
  display: flex;
  justify-content: center;
}

.card-header {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.card-title{
  font-size: 36px;
}
.card-footer {
  display: flex;
  justify-content: center; 
  align-items: center; 
}
/* 底部 */
.info-container {
  padding: 20px;
}

.info-row {
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.info-col {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  text-align: center;
}

.info-content {
  width: 100%;
}
.qrcode-image {
  max-width: 30%; 
  height: auto;
  margin: 10px 0;
}

.divider {
  height: 1px;
  background-color: #aaa;
  border: none;
  margin-bottom: 20px;
}

.footer {
  margin-top: 10px;
  text-align: center;
}

.beian-link {
  font-size: 12px;
  color: #aaa;
  text-decoration: none;
}
</style>